<template>
    <div>首页</div>
</template>

<script>
export default {
    name: 'HomeView',
    data() {
        return {}
    },
    computed: {},
    methods: {},
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'></style>